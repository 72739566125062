import Vue from 'vue';
import axios from 'axios';
import store from "@/plugins/store";
import router from '@/plugins/routes';
import _ from "lodash";

const state = {
    authToken: null,
    user: null,
    clientInfo: null,
    currentClient: null,
    currentClientUUID: null,
    isOnline: null, // null=pending, true=online, false=offline
};
const getters = {
    isAuthenticated: state => {
        return !_.isEmpty(state.authToken);
    },
    appOnline: state => state.isOnline,
    userIsFiller: state => {
        return (!_.isEmpty(state.clientInfo) && state.clientInfo.roles.length === 1 && state.clientInfo.roles.includes('authenticated'));
    },
    userIsAdmin: state => {
        return (!_.isEmpty(state.clientInfo) && !_.isEmpty(state.clientInfo.roles) && state.clientInfo.roles.includes('admin'));
    },
    serverNotification: state => {
        if (!_.isEmpty(state.user) && !_.isEmpty(state.user.server_notification)) {
            return {
                'type': state.user.server_notification.type, // 'info', 'warning', 'error', 'success
                'message': state.user.server_notification.message,
            };
        }
    },
    termsAndConditionsRequired: state => {
        let requiredClients = [];
        if (!_.isEmpty(state.user) && !_.isEmpty(state.user.clients)) {
            for (const client of state.user.clients) {
                if (client.terms_and_conditions_required) {
                    requiredClients.push(client.uuid)
                }
            }
        }
        return requiredClients;
    },
    StateUser: state => state.user,
    StateClientInfo: state => state.clientInfo,
    CurrentClient: state => state.currentClient,
    CurrentClientUUID: state => state.currentClientUUID,
    CurrentClientName: (state) => {
        if (_.isEmpty(state.user) || _.isEmpty(state.user.clients)) {
            return undefined;
        }
        let clientObj = state.user.clients.find(element => element.id === state.currentClient);
        if (clientObj === undefined) {
            return undefined;
        }
        else {
            return clientObj.name;
        }
    },
    getUserEmail: (state) => {
        if (!_.isEmpty(state.user)) {
            return state.user.email;
        }
        return '';
    },
    userDashboardRouteName: (state, getters) => {
        if (getters.userIsFiller) {
            return 'folder_list'
        }
        else {
            return 'dashboard'
        }
    }
};
const actions = {
    async LogIn ({ commit, dispatch}, {username, password}) {
        return new Promise((resolve, reject) => {
            const user = new FormData();
            // Add login data
            user.append("username", username);
            user.append("password", password);

            axios.post('api/user/login', user).then(async responseLogin => {
                if (responseLogin.data.status === true) {
                    // Save auth tokens
                    await commit('setAuthTokens', responseLogin.data.api_key)
                    // Fetch basic login data
                    await commit('setUser', responseLogin.data);
                    await dispatch('UserInfo');
                    // Sync saved registrations
                    await dispatch('syncSavedRegistrations')
                }
                // Return login status and message, can be a successfull or failed login
                resolve(responseLogin.data)
            }).catch(error => {
                // Login failed
                reject(error)
            })
        })
    },
    async UserInfo ({ commit, dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get('api/user/info').then(async function (response) {
                // Save user info when we have data back
                if (!_.isEmpty(response.data) && response.status === 200) {
                    // Make sure the user has clients, since the app assumes the logged-in user has at least 1 client.
                    if (_.isEmpty(response.data.clients)) {
                        // Show message
                        Vue.$toast.warning("Uw account is niet toegewezen aan een klant en bent daarom automatisch uitgelogd.", {timeout: false});
                        // Logout user
                        await commit('LogOut')
                        // Redirect to login page
                        await router.push({name: 'login'})
                        reject()
                    }
                    else {
                        await commit('clearOldFieldValues');
                        await commit('updateUserInfo', response.data)
                        // Fetch client info
                        await dispatch('ClientInfo');
                        resolve()
                    }
                }
            }).catch(async function(error) {
                console.log("Error while retrieving user info.")
                reject(error)
            })
        })
    },
    async ClientInfo ({ commit }) {
        // Get the currently selected client ID
        let currentClient = store.getters.CurrentClient;

        return new Promise((resolve, reject) => {
            axios.get('api/client/'+currentClient+'/info').then(async function (response) {
                // Save client info when we have data back
                if (response.data) {
                    await commit('setClientInfo', response.data)
                    resolve()
                }
            }).catch(error => {
                console.log("Error while retrieving client info.")
                reject(error)
            })
        })
    },
    async LogOut({commit}){
        // Unset user
        commit('LogOut')
        // Delete all caches
        store.dispatch('storeCache/cacheDeleteAll')
        // Delete all saved registrations
        store.commit('deleteAllSavedRegistrations')
    },
};
const mutations = {
    setOnlineStatus(state, boolOnline) {
        state.isOnline = boolOnline;
    },
    setAuthTokens(state, token){
        state.authToken = token
    },
    // Set basic user info when logging in
    setUser(state, userInfo){
        state.user = {
            id: userInfo.id,
            email: userInfo.email,
            clients: userInfo.clients,
            server_notification: null,
        }
    },
    // Set additional user info. This info is polled from the server every X minutes.
    updateUserInfo(state, userInfo){
        state.user.clients = userInfo.clients
        state.user.server_notification = userInfo.server_notification

        if (!state.currentClient) {
            if (state.user.clients && state.user.clients.length > 0) {
                state.currentClient = state.user.clients[0].id;
                state.currentClientUUID = state.user.clients[0].uuid;
            }
        }

        // Check if the currently selected user still exists in the client options
        if (!state.user.clients.find(client => client.id === state.currentClient)) {
            console.log('Changing the currently selected client because the selected client does not exists in the client options anymore.')
            // Get the first available client in the client options
            let firstAvailableClient = state.user.clients[0].id
            console.log('The first available client is: '+firstAvailableClient)
            // Change currently selected client
            this.commit('setCurrentClient', firstAvailableClient)
        }
    },
    setClientInfo(state, clientInfo){
        state.clientInfo = clientInfo
    },
    async setCurrentClient(state, myValue) {
        if (_.isEmpty(myValue) || !state.user.clients.find(client => client.id === myValue)) {
            return false;
        }
        // Temporarily empty all permissions and roles for the user to prevent having old permissions/roles when switching clients
        state.clientInfo.permissions = [];
        state.clientInfo.roles = [];

        state.currentClient = myValue;
        // Get client by ID
        let clientObj = state.user.clients.find(element => element.id === myValue);
        state.currentClientUUID = clientObj.uuid;

        // Fetch new client info
        await this.dispatch('ClientInfo');

        // Show success message
        await Vue.$toast.success('Je kan nu met klant "'+ this.getters.CurrentClientName +'" werken.');
    },
    LogOut(state){
        state.authToken = null;
        state.user = null;
        state.currentClient = null;
        state.currentClientUUID = null;
        // // Remove old unused oauth tokens
        // // @TODO: Remove this after a couple of weeks/months when all users are migrated.
        delete state.access_token;
        delete state.refresh_token;
        delete state.token_expire_timestamp;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};